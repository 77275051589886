<template>
    <div>
        <div v-for="stock in row.item.stocks">{{ stock }}</div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>